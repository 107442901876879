import 'core-js'
import 'regenerator-runtime/runtime'

if (typeof Promise === 'undefined') {
    require('promise/lib/rejection-tracking').enable()
    window.Promise = require('promise/lib/es6-extensions.js')
}

Object.assign = require('object-assign')

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function(searchElement, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }

            const o = Object(this)
            const len = o.length >>> 0

            if (len === 0) {
                return false
            }

            const n = fromIndex | 0
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

            while (k < len) {
                if (o[k] === searchElement) {
                    return true
                }
                k++
            }
            return false
        }
    })
}

if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
        if (typeof start !== 'number') {
            start = 0
        }
        if (start + search.length > this.length) {
            return false
        } else {
            return this.indexOf(search, start) !== -1
        }
    }
}
